import { TiaraTracker } from '@/types/tiara-shim'

/* tslint:disable:interface-name */
declare global {
  interface Window {
    // @ts-expect-error
    TiaraTracker: TiaraTracker
  }
}
/* tslint:enable */

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class DummyLogger {
  public static actionKind(): DummyLogger {
    return DummyLogger
  }

  public static click(): DummyLogger {
    return DummyLogger
  }

  public static customProps<T>(): DummyLogger {
    return DummyLogger
  }

  public static ecommerce(): DummyLogger {
    return DummyLogger
  }

  public static ecommerceContents(): DummyLogger {
    return DummyLogger
  }

  public static getInstance(): DummyLogger {
    return DummyLogger
  }

  public static init(): DummyLogger {
    return DummyLogger
  }

  public static initTracker(): DummyLogger {
    return DummyLogger
  }

  public static pageMeta(): DummyLogger {
    return DummyLogger
  }

  public static search(): DummyLogger {
    return DummyLogger
  }

  public static setAccessToken(): DummyLogger {
    return DummyLogger
  }

  public static setAdTrackId(): DummyLogger {
    return DummyLogger
  }

  public static setAppUserId(): DummyLogger {
    return DummyLogger
  }

  public static setDeployment(): DummyLogger {
    return DummyLogger
  }

  public static setDisableQuery(): DummyLogger {
    return DummyLogger
  }

  public static setEnableHash(): DummyLogger {
    return DummyLogger
  }

  public static setIgnoreQueryParams(): DummyLogger {
    return DummyLogger
  }

  public static setPage(): DummyLogger {
    return DummyLogger
  }

  public static setReferrer(): DummyLogger {
    return DummyLogger
  }

  public static setSection(): DummyLogger {
    return DummyLogger
  }

  public static setSessionTimeout(): DummyLogger {
    return DummyLogger
  }

  public static setSvcDomain(): DummyLogger {
    return DummyLogger
  }

  public static setThirdAdAgree(): DummyLogger {
    return DummyLogger
  }

  public static setThirdProvideAgree(): DummyLogger {
    return DummyLogger
  }

  public static setTitle(): DummyLogger {
    return DummyLogger
  }

  public static setUrl(): DummyLogger {
    return DummyLogger
  }

  public static track(): DummyLogger {
    return DummyLogger
  }

  public static trackEvent(): DummyLogger {
    return DummyLogger
  }

  public static trackUsage(): DummyLogger {
    return DummyLogger
  }

  public static trackViewImp(): DummyLogger {
    return DummyLogger
  }

  public static usage(): DummyLogger {
    return DummyLogger
  }

  public static viewImpContents(): DummyLogger {
    return DummyLogger
  }
}

const SafeTiaraLogger = (() => {
  if (typeof window === 'undefined') return DummyLogger
  else return window.TiaraTracker || DummyLogger
})()

export default SafeTiaraLogger
