import { RoutePaths } from '@/shared/constants'
import SafeTiaraLogger from '@/helpers/logger/SafeTiaraLogger'
import {
  TiaraClick,
  TiaraDeployment,
  TiaraEventMeta,
  TiaraPageMetaParam,
  TiaraUsageParam,
} from '@/types/tiara-shim'
import { LogEventName, LogPageName } from '@/enums/logger'
import { captureException } from '@sentry/browser'
import { BookingVertical } from '@/enums'
import { configureScope } from '@sentry/nextjs'
import { SentryTagType } from '@/plugins/components/sentry'

enum LoggerType {
  SENTRY = 'sentry',
  TIARA = 'tiara',
  CONSOLE = 'console',
}

enum LogLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

interface LogOption {
  level: LogLevel
  message: any
}

type LogTypeOption = {
  [key in LoggerType]: boolean
}

type Index = (option: LogOption) => void

type LoggerPack = {
  [key in LoggerType]: Index
}

// @ts-expect-error
SafeTiaraLogger.getInstance().init({
  svcDomain: process.env.NEXT_PUBLIC_DOMAIN,
  deployment: process.env.NEXT_PUBLIC_DEPLOYMENT as TiaraDeployment,
})

const loggerPack: Partial<LoggerPack> = {
  [LoggerType.CONSOLE]: (function createStdoutLogger(): Index | undefined {
    if (typeof window === 'undefined') return /* guard */
    const logBowl = window.document.createElement('img')
    logBowl.style.position = 'absolute'
    logBowl.style.visibility = 'hidden'
    logBowl.style.opacity = '0'
    logBowl.style.width = '0'
    logBowl.style.height = '0'
    window.document.body.appendChild(logBowl)
    return function stdoutLogger({ level, message }) {
      logBowl.src = `${
        RoutePaths.LOG_PATH
      }?level=${level}&message=${encodeURIComponent(message)}`
    }
  })(),
  [LoggerType.TIARA]: function logTiara() {
    SafeTiaraLogger.getInstance()
  },
}

function log(option: Partial<LogOption> & Partial<LogTypeOption>): void {
  const { level = LogLevel.INFO, message } = option
  for (const loggerName in loggerPack) {
    // eslint-disable-next-line no-prototype-builtins
    if (!option?.hasOwnProperty(loggerName)) continue
    loggerPack[loggerName as LoggerType]?.({ level, message })
  }
}

export function logUX(message: any): void {
  return log({
    [LoggerType.CONSOLE]: true,
    [LoggerType.TIARA]: true,
    message,
  })
}

export function createUsageParam(): () => TiaraUsageParam {
  const duration = Date.now()
  return function getUsageParam() {
    if (typeof window === 'undefined') {
      return {
        duration: 0,
        scroll_bottom: 0,
        scroll_height: 0,
        scroll_inner_height: 0,
        scroll_percent: 0,
        scroll_top: 0,
      }
    } else {
      const scroll_height = window.document.body.scrollHeight
      const scroll_top = window.document.body.scrollTop
      const scroll_inner_height = window.document.body.offsetHeight
      const scroll_bottom = scroll_top + scroll_inner_height
      return {
        duration: Date.now() - duration,
        scroll_bottom,
        scroll_height,
        scroll_inner_height,
        scroll_percent: Math.round((scroll_bottom / scroll_height) * 100),
        scroll_top,
      }
    }
  }
}

export function logPage(
  pageName: LogPageName,
  pageMeta?: TiaraPageMetaParam,
  customProps?: Record<string, string>
): void {
  try {
    if (pageMeta) {
      SafeTiaraLogger.getInstance()
        // @ts-expect-error
        .setPage(pageName)
        .setSection(BookingVertical.SHUTTLE)
        .trackPage(pageName)
        .pageMeta(pageMeta)
        .customProps(
          Object.keys(customProps ?? {}).length === 0 ? undefined : customProps
        )
        .track()
    }
    if (!pageMeta) {
      SafeTiaraLogger.getInstance()
        // @ts-expect-error
        .setPage(pageName)
        .setSection(BookingVertical.SHUTTLE)
        .trackPage(pageName)
        .customProps(
          Object.keys(customProps ?? {}).length === 0 ? undefined : customProps
        )
        .track()
    }
  } catch (exception: any) {
    configureScope(function (scope) {
      // scope.setLevel('debug')
      scope.setTag(SentryTagType.handled, 'true')
      captureException(new Error(exception))
    })
  }
}

export function logPageViewEffect(
  pageName: LogPageName,
  pageMeta?: TiaraPageMetaParam,
  customProps?: Record<string, string>,
  usageEffect: boolean = true
): () => void {
  return function effectCallback() {
    logPage(pageName, pageMeta, customProps)
    const getUsage = createUsageParam()
    return (
      usageEffect &&
      function effectDestroyCallback() {
        const usage = usageEffect && getUsage()
        try {
          SafeTiaraLogger.getInstance()
            // @ts-expect-error
            .setPage(pageName)
            .usage(usage)
            .trackUsage()
            .track()
        } catch (exception: any) {
          configureScope(function (scope) {
            // scope.setLevel('debug')
            scope.setTag(SentryTagType.handled, 'true')
            captureException(new Error(exception))
          })
        }
      }
    )
  }
}

export function logRentBusPage(
  pageName: LogPageName,
  pageMeta?: TiaraPageMetaParam
): void {
  try {
    if (pageMeta) {
      SafeTiaraLogger.getInstance()
        // @ts-expect-error
        .setPage(pageName)
        .setSection(BookingVertical.RENTBUS)
        .trackPage(pageName)
        .pageMeta(pageMeta)
        .track()
    }
    if (!pageMeta) {
      SafeTiaraLogger.getInstance()
        // @ts-expect-error
        .setPage(pageName)
        .setSection(BookingVertical.RENTBUS)
        .trackPage(pageName)
        .track()
    }
  } catch (exception: any) {
    configureScope(function (scope) {
      // scope.setLevel('debug')
      scope.setTag(SentryTagType.handled, 'true')
      captureException(new Error(exception))
    })
  }
}

export function logRentBusPageViewEffect(
  pageName: LogPageName,
  pageMeta?: TiaraPageMetaParam,
  usageEffect: boolean = true
): () => void {
  return function effectCallback() {
    logRentBusPage(pageName, pageMeta)
    const getUsage = createUsageParam()
    return (
      usageEffect &&
      function effectDestroyCallback() {
        const usage = usageEffect && getUsage()
        try {
          SafeTiaraLogger.getInstance()
            // @ts-expect-error
            .setPage(pageName)
            .usage(usage)
            .trackUsage()
            .track()
        } catch (exception: any) {
          configureScope(function (scope) {
            // scope.setLevel('debug')
            scope.setTag(SentryTagType.handled, 'true')
            captureException(new Error(exception))
          })
        }
      }
    )
  }
}

export function createLogClickEvent(
  pageName: LogPageName
): (
  eventName: LogEventName,
  clickData?: TiaraClick,
  eventMeta?: TiaraEventMeta,
  customProps?: Record<string, string>
) => void {
  return function logEvent(
    eventName,
    clickData = {},
    eventMeta = {},
    customProps = {}
  ) {
    try {
      if (eventMeta) {
        SafeTiaraLogger.getInstance()
          // @ts-expect-error
          .setPage(pageName)
          .trackEvent(eventName)
          .click(clickData)
          .eventMeta(eventMeta)
          .customProps(
            Object.keys(customProps).length === 0 ? undefined : customProps
          )
          .track()
      } else {
        SafeTiaraLogger.getInstance()
          // @ts-expect-error
          .setPage(pageName)
          .trackEvent(eventName)
          .click(clickData)
          .customProps(
            Object.keys(customProps).length === 0 ? undefined : customProps
          )
          .track()
      }
    } catch (exception: any) {
      configureScope(function (scope) {
        // scope.setLevel('debug')
        scope.setTag(SentryTagType.handled, 'true')
        captureException(new Error(exception))
      })
    }
  }
}
