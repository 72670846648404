export enum LogType {
  PAGE_VIEW = 'PageView', // 화면 뷰에 대한 통계를 수집
  EVENT = 'Event', // 사용자의 액션 이벤트를 수집
  ECOMMERCE = 'Ecommerce', // 구매가 일어났을때 구매 정보를 수집
  Usage = 'Usage', // 체류시간, 스크롤 비율 등을 수집
  VIEW_IMP = 'ViewImp', // 콘텐츠 노출 정보를 수집
  LOCATION = 'Location', // 사용자의 위치정보를 수집
}

export enum LogPageName {
  SHUTTLE_MAIN = '셔틀_홈',
  SHUTTLE_TAG_DETAIL = '셔틀_태그상세',

  SHUTTLE_NEW_CROWD = '셔틀_오픈셔틀생성_공동대절',
  SHUTTLE_NEW_CHARTER = '셔틀_오픈셔틀생성_전체대절',
  SHUTTLE_NEW_CROWD_CALCULATION = '셔틀_오픈셔틀생성_공동대절_차량_요금확인',
  SHUTTLE_NEW_CHARTER_CALCULATION = '셔틀_오픈셔틀생성_전체대절_차량_요금확인',
  SHUTTLE_NEW_CROWD_CHECKOUT = '셔틀_오픈셔틀생성_공동대절_정보입력',
  SHUTTLE_NEW_CHARTER_CHECKOUT = '셔틀_오픈셔틀생성_전체대절_정보입력',

  SHUTTLE_NO_CREDIT_CARD = '셔틀_카드미등록',

  SHUTTLE_ITEM_OPEN_SHUTTLE = '셔틀_오픈셔틀',
  SHUTTLE_ITEM_T_SHUTTLE = '셔틀_T셔틀상품',

  SHUTTLE_NEW_CROWD_BOOKING_SCHEDULE = '셔틀_오픈셔틀생성_공동대절_탑승인원선택',
  SHUTTLE_ITEM_OPEN_BOOKING_SCHEDULE = '셔틀_오픈셔틀예약',
  SHUTTLE_ITEM_T_SHUTTLE_BOOKING_SCHEDULE = '셔틀_T셔틀예약',

  SHUTTLE_T_SHUTTLE_BOOKING_OPEN_STASTION_FILTER = '셔틀_T셔틀예약_출발지상세',
  SHUTTLE_T_SHUTTLE_BOOKING_DATE_FILTER = '셔틀_T셔틀예약_출발일자선택',

  SHUTTLE_BOOKING_PICK_SEAT = '셔틀_좌석선택',
  SHUTTLE_ORDER_PAYMENT = '셔틀_결제하기',
  RENT_BUS_ORDER_PAYMENT = '버스대절_결제하기',

  SHUTTLE_BOOKING_HISTORY_LIST = '셔틀_웹_이용내역',
  SHUTTLE_BOOKING_ITEM = '셔틀_예약상세',
  SHUTTLE_BOOKING_ITEM_CANCEL = '셔틀_예약상세_예약취소',
  RENT_BUS_BOOKING_ITEM_CANCEL = '버스대절_예약상세_예약취소',
  SHUTTLE_ERROR = '셔틀_에러',

  SHUTTLE_UNPAID = '셔틀_미수회수',
  SHUTTLE_TICKET = '셔틀_탑승권',
  CHARTER_MANUAL = '서비스소개_페이지뷰',
  RENT_BUS_HOME = '버스대절_홈_페이지뷰',
  RENT_BUS_CALCULATION = '버스대절_차량선택',
  RENT_BUS_BOOKING = '버스대절_예약완료',
  RENT_BUS_EXTRA_INFO = '버스대절_추가정보_입력_화면',
  SHUTTLE_FESTIVAL_HOME = '셔틀_페스티벌홈',

  JEJU_SHUTTLE = '셔틀_제주셔틀홈',
}

export enum LogEventName {
  SHUTTLE_HOME_CATEGORY_CLICK = '셔틀_카테고리_클릭',
  SHUTTLE_SHARE_BUTTON_CLICK = '셔틀_공유하기',
  전체대절_예약취소_사유수집_돌아가기버튼클릭 = '전체대절_예약취소_사유수집_돌아가기버튼클릭',
  전체대절_예약취소_사유수집_확인버튼클릭 = '전체대절_예약취소_사유수집_확인버튼클릭',
  공공대절_예약취소_사유수집_돌아가기버튼클릭 = '공공대절_예약취소_사유수집_돌아가기버튼클릭',
  공공대절_예약취소_사유수집_확인버튼클릭 = '공공대절_예약취소_사유수집_확인버튼클릭',
  SHUTTLE_HOME_CLICK_NEW_CROWD = '셔틀_홈_오픈셔틀_공동대절클릭',
  SHUTTLE_HOME_CLICK_NEW_CHARTER = '셔틀_홈_오픈셔틀_전체대절클릭',
  SHUTTLE_HOME_CLICK_CROWD_ITEM = '셔틀_홈_모집중오픈셔틀클릭',
  SHUTTLE_HOME_CLICK_ITEM = '셔틀_홈_상품클릭',
  SHUTTLE_HOME_CLICK_LOAD_MORE = '셔틀_홈_셔틀상품더보기클릭',
  SHUTTLE_HOME_CLICK_MARKETING_BANNER = '셔틀_홈_마케팅배너클릭',
  SHUTTLE_HOME_CLICK_BUSINESS_BANNER = '셔틀_홈_비지니스제휴문의클릭',

  SHUTTLE_HOME_CLICK_TAG = '셔틀_홈_태그클릭',
  SHUTTLE_TAG_DETAIL_CLICK_TAG = '셔틀_태그상세_태그클릭',
  SHUTTLE_TAG_DETAIL_CLICK_ITEM = '셔틀_태그상세_상품클릭',

  SHUTTLE_NEW_CROWD_CLICK_CHARTER_TYPE = '셔틀_오픈셔틀생성_공동대절_타입변경_전체대절클릭',
  SHUTTLE_NEW_CROWD_CLICK_CROWD_TYPE = '셔틀_오픈셔틀생성_공동대절_타입변경_공동대절클릭',
  SHUTTLE_NEW_CROWD_CLICK_CALCULATION = '셔틀_오픈셔틀생성_공동대절_요금확인클릭',

  SHUTTLE_NEW_CROWD_CLICK_PROMOTIOM_BANNER = '셔틀_오픈셔틀생성_공동대절_프로모션배너클릭',
  SHUTTLE_NEW_CROWD_CLICK_ONE_WAY = '셔틀_오픈셔틀생성_공동대절_편도클릭',
  SHUTTLE_NEW_CROWD_CLICK_ROUND_TRIP = '셔틀_오픈셔틀생성_공동대절_왕복클릭',
  SHUTTLE_NEW_CROWD_CLICK_DEPARTURE_SPOT = '셔틀_오픈셔틀생성_공동대절_출발지_장소입력',
  SHUTTLE_NEW_CROWD_CLICK_DEPARTURE_DATE = '셔틀_오픈셔틀생성_공동대절_출발지_출발일자입력',
  SHUTTLE_NEW_CROWD_CLICK_DEPARTURE_TIME = '셔틀_오픈셔틀생성_공동대절_출발지_출발시간입력',
  SHUTTLE_NEW_CROWD_CLICK_ARRIVAL_SPOT = '셔틀_오픈셔틀생성_공동대절_목적지_장소입력',
  SHUTTLE_NEW_CROWD_CLICK_ARRIVAL_DATE = '셔틀_오픈셔틀생성_공동대절_목적지_출발일자입력',
  SHUTTLE_NEW_CROWD_CLICK_ARRIVAL_TIME = '셔틀_오픈셔틀생성_공동대절_목적지_출발시간입력',
  SHUTTLE_NEW_CROWD_CLICK_NEXT = '셔틀_오픈셔틀생성_공동대절_다음클릭',
  SHUTTLE_NEW_CROWD_CLICK_CHANGE_DATETIME_CONFIRM = '셔틀_오픈셔틀생성_공동대절_탑승일자변경_확인클릭',
  SHUTTLE_NEW_CROWD_CLICK_CHANGE_DATETIME_CANCEL = '셔틀_오픈셔틀생성_공동대절_탑승일자변경_취소클릭',

  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_12 = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_12인승선택',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_12_SEATGRID = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_좌석배치선택(12인승)',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_18 = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_18인승선택',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_18_SEATGRID = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_좌석배치선택(18인승)',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_28 = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_28인승선택',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_28_SEATGRID = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_좌석배치선택(28인승)',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_44 = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_44인승선택',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_SEAT_44_SEATGRID = '셔틀_오픈셔틀생성_공동대절_차량_요금확인_좌석배치선택(44인승)',
  SHUTTLE_NEW_CROWD_CALCULATION_CLICK_NEXT = '셔틀_오픈셔틀생성_공동대절_다음클릭',

  SHUTTLE_NEW_CROWD_CHECKOUT_CLICK_NEXT = '셔틀_오픈셔틀생성_공동대절_정보입력_다음클릭',

  SHUTTLE_NO_CREDIT_CARD_REGISTER = '셔틀_카드미등록_등록클릭',
  SHUTTLE_NO_CREDIT_CARD_CANCEL = '셔틀_카드미등록_나중에클릭',
  SHUTTLE_NO_CREDIT_CARD_WEB_CONFIRM = '셔틀_카드미등록_웹_확인클릭',

  SHUTTLE_NEW_CROWD_CLICK_BOOKING_SCHEDULE = '셔틀_오픈셔틀생성_공동대절_좌석선택클릭',

  SHUTTLE_NEW_CHARTER_CLICK_CHARTER_TYPE = '셔틀_오픈셔틀생성_전체대절_타입변경_전체대절클릭',
  SHUTTLE_NEW_CHARTER_CLICK_CROWD_TYPE = '셔틀_오픈셔틀생성_전체대절_타입변경_공동대절대절클릭',
  SHUTTLE_NEW_CHARTER_CLICK_CALCULATION = '셔틀_오픈셔틀생성_전체대절_요금확인클릭',

  SHUTTLE_NEW_CHARTER_CLICK_PROMOTIOM_BANNER = '셔틀_오픈셔틀생성_전체대절_프로모션배너클릭',
  SHUTTLE_NEW_CHARTER_CLICK_ONE_WAY = '셔틀_오픈셔틀생성_전체대절_편도클릭',
  SHUTTLE_NEW_CHARTER_CLICK_ROUND_TRIP = '셔틀_오픈셔틀생성_전체대절_왕복클릭',
  SHUTTLE_NEW_CHARTER_CLICK_DEPARTURE_SPOT = '셔틀_오픈셔틀생성_전체대절_출발지_장소입력',
  SHUTTLE_NEW_CHARTER_CLICK_DEPARTURE_DATE = '셔틀_오픈셔틀생성_전체대절_출발지_출발일자입력',
  SHUTTLE_NEW_CHARTER_CLICK_DEPARTURE_TIME = '셔틀_오픈셔틀생성_전체대절_출발지_출발시간입력',
  SHUTTLE_NEW_CHARTER_CLICK_ARRIVAL_SPOT = '셔틀_오픈셔틀생성_전체대절_목적지_장소입력',
  SHUTTLE_NEW_CHARTER_CLICK_ARRIVAL_DATE = '셔틀_오픈셔틀생성_전체대절_목적지_출발일자입력',
  SHUTTLE_NEW_CHARTER_CLICK_ARRIVAL_TIME = '셔틀_오픈셔틀생성_전체대절_목적지_출발시간입력',
  SHUTTLE_NEW_CHARTER_CLICK_NEXT = '셔틀_오픈셔틀생성_전체대절_다음클릭',
  SHUTTLE_NEW_CHARTER_CLICK_CHANGE_DATETIME_CONFIRM = '셔틀_오픈셔틀생성_전체대절_탑승일자변경_확인클릭',
  SHUTTLE_NEW_CHARTER_CLICK_CHANGE_DATETIME_CANCEL = '셔틀_오픈셔틀생성_전체대절_탑승일자변경_취소클릭',

  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_12 = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_12인승선택',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_12_SEATGRID = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_좌석배치선택(12인승)',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_18 = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_18인승선택',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_18_SEATGRID = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_좌석배치선택(18인승)',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_28 = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_28인승선택',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_28_SEATGRID = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_좌석배치선택(28인승)',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_44 = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_44인승선택',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_SEAT_44_SEATGRID = '셔틀_오픈셔틀생성_전체대절_차량_요금확인_좌석배치선택(44인승)',
  SHUTTLE_NEW_CHARTER_CALCULATION_CLICK_NEXT = '셔틀_오픈셔틀생성_전체대절_다음클릭',

  SHUTTLE_NEW_CHARTER_CHECKOUT_CLICK_NEXT = '셔틀_오픈셔틀생성_전체대절_정보입력_다음클릭',

  SHUTTLE_BOOKING_CLICK_PICK_SEAT_CONFIRM = '셔틀_좌석선택_완료클릭',

  SHUTTLE_ORDER_CLICK_PAYMENT_REGISTER = '셔틀_결제하기_카드등록클릭',
  SHUTTLE_ORDER_CLICK_WEB_REFRESH = '셔틀_결제하기_웹_새로고침클릭',
  SHUTTLE_ORDER_CLICK_PAYMENT_METHOD = '셔틀_결제하기_카드목록클릭',
  SHUTTLE_ORDER_CLICK_ALL_AGREE = '셔틀_결제하기_전체동의하기클릭',
  RENT_BUS_ORDER_CLICK_ALL_AGREE = '버스대절_결제하기_전체동의하기클릭',
  SHUTTLE_ORDER_CLICK_AGREE_1 = '셔틀_결제하기_개별동의1_클릭',
  RENT_BUS_ORDER_CLICK_AGREE_1 = '버스대절_결제하기_개별동의1_클릭',
  SHUTTLE_ORDER_CLICK_AGREE_2 = '셔틀_결제하기_개별동의2_클릭',
  RENT_BUS_ORDER_CLICK_AGREE_2 = '버스대절_결제하기_개별동의2_클릭',
  SHUTTLE_ORDER_CLICK_AGREE_3 = '셔틀_결제하기_개별동의3_클릭',
  RENT_BUS_ORDER_CLICK_AGREE_3 = '버스대절_결제하기_개별동의3_클릭',
  SHUTTLE_ORDER_CLICK_POLICY_1 = '셔틀_결제하기_약관상세1_클릭',
  RENT_BUS_ORDER_CLICK_POLICY_1 = '버스대절_결제하기_약관상세1_클릭',
  SHUTTLE_ORDER_CLICK_POLICY_2 = '셔틀_결제하기_약관상세2_클릭',
  RENT_BUS_ORDER_CLICK_POLICY_2 = '버스대절_결제하기_약관상세2_클릭',
  SHUTTLE_ORDER_CLICK_POLICY_3 = '셔틀_결제하기_약관상세3_클릭',
  RENT_BUS_ORDER_CLICK_POLICY_3 = '버스대절_결제하기_약관상세3_클릭',
  SHUTTLE_ORDER_CLICK_PAYMENT = '셔틀_결제하기_결제하기클릭',
  RENT_BUS_ORDER_CLICK_PAYMENT = '버스대절_결제하기_결제하기클릭',

  SHUTTLE_ORDER_PAYMENT_METHOD_SELECT_CARD = '셔틀_결제하기_결제방법선택_카드선택',
  SHUTTLE_ORDER_PAYMENT_METHOD_ADD_CARD = '셔틀_결제하기_결제방법선택_카드추가선택',
  SHUTTLE_ORDER_PAYMENT_METHOD_CLOSE = '셔틀_결제하기_결제방법선택_닫기',

  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_ITEM = '셔틀_예약상세_상품클릭',
  RENT_BUS_BOOKING_ITEM_CLICK_SHUTTLE_ITEM = '버스대절_예약상세_상품클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_ITEM_DETAIL = '셔틀_예약상세_상품상세보기클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_SEAT = '셔틀_예약상세_좌석확인하기클릭',
  RENT_BUS_BOOKING_ITEM_CLICK_SHUTTLE_SEAT = '버스대절_예약상세_좌석확인하기클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_SHARE = '셔틀_예약상세_링크공유클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_CANCEL = '셔틀_예약상세_예약취소클릭',
  RENT_BUS_BOOKING_ITEM_CLICK_SHUTTLE_CANCEL = '셔틀_예약상세_예약취소클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_TICKET = '셔틀_예약상세_탑승권확인클릭',
  RENT_BUS_BOOKING_ITEM_CLICK_SHUTTLE_TICKET = '버스대절_예약상세_탑승권확인클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_CALL = '셔틀_예약상세_전화하기클릭',
  SHUTTLE_BOOKING_ITEM_CLICK_SHUTTLE_MAP = '셔틀_예약상세_카카오맵보기클릭',
  RENT_BUS_BOOKING_ITEM_CLICK_SHUTTLE_MAP = '셔틀_예약상세_카카오맵보기클릭',
  셔틀_예약상세_탑승장소사진이미지클릭 = '셔틀_예약상세_탑승장소사진이미지클릭',

  SHUTTLE_BOOKING_CLICK_CANCEL_CONFIRM = '셔틀_예약취소_취소하기클릭',
  RENT_BUS_BOOKING_CLICK_CANCEL_CONFIRM = '버스대절_예약취소_취소하기클릭',
  SHUTTLE_BOOKING_CLICK_CANCEL_CANCEL = '셔틀_예약취소_아니오클릭',
  RENT_BUS_BOOKING_CLICK_CANCEL_CANCEL = '버스대절_예약취소_아니오클릭',

  SHUTTLE_ITEM_OPEN_SHUTTLE_CLICK_SHARE_LINK = '셔틀_오픈셔틀_링크공유클릭',
  SHUTTLE_ITEM_OPEN_SHUTTLE_CLICK_OUTLINK = '셔틀_오픈셔틀_아웃링크클릭',
  SHUTTLE_ITEM_OPEN_SHUTTLE_CLICK_STATION_DETAIL = '셔틀_오픈셔틀_정류장상세클릭',
  SHUTTLE_ITEM_OPEN_SHUTTLE_CLICK_SCHEDULE_DETAIL = '셔틀_오픈셔틀_스케줄상세클릭',
  SHUTTLE_ITEM_OPEN_SHUTTLE_CLICK_BOOKING = '셔틀_오픈셔틀_예약하기클릭',

  SHUTTLE_OPEN_SHUTTLE_BOOKING_PICK_SEAT = '셔틀_오픈셔틀예약_좌석선택하기클릭',

  SHUTTLE_ITEM_T_SHUTTLE_CLICK_SHARE_LINK = '셔틀_T셔틀상품_링크공유클릭',
  SHUTTLE_ITEM_T_SHUTTLE_CLICK_OUTLINK = '셔틀_T셔틀상품_아웃링크클릭',
  SHUTTLE_ITEM_T_SHUTTLE_CLICK_STATION_DETAIL = '셔틀_T셔틀상품_정류장상세클릭',
  SHUTTLE_ITEM_T_SHUTTLE_CLICK_SCHEDULE_DETAIL = '셔틀_T셔틀상품_스케줄상세클릭',
  셔틀_T셔틀상품_스케줄탑승장소확인사진클릭 = '셔틀_T셔틀상품_스케줄탑승장소확인사진클릭',
  SHUTTLE_ITEM_T_SHUTTLE_CLICK_BOOKING = '셔틀_T셔틀상품_예약하기클릭',
  SHUTTLE_ITEM_T_SHUTTLE_CLICK_BANNER = '셔틀_T셔틀상품_배너클릭',

  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_OPEN_STASTION_FILTER = '셔틀_T셔틀예약_탑승장소선택',
  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_DATE_FILTER = '셔틀_T셔틀예약_출발일자선택',
  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_SCHEDULE = '셔틀_T셔틀예약_스케줄선택',

  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_OPEN_STASTION_FILTER_EXPAND = '셔틀_T셔틀예약_출발지상세_확장클릭',
  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_OPEN_STASTION_FILTER_KAKAOMAP = '셔틀_T셔틀예약_출발지상세_카카오맵클릭',
  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_OPEN_STASTION_FILTER_STATION = '셔틀_T셔틀예약_출발지상세_정류장클릭',

  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_CONFIRM = '셔틀_T셔틀예약_확인클릭',
  SHUTTLE_T_SHUTTLE_BOOKING_CLICK_CANCEL = '셔틀_T셔틀예약_취소클릭',

  SHUTTLE_TICKET_SEAT_MAP = '셔틀_탑승권_좌석확인클릭',

  SHUTTLE_UNPAID_COLLECT_CLICK_PAYMENT_METHOD = '셔틀_미수회수_결제수단클릭',
  SHUTTLE_UNPAID_COLLECT_CLICK_CHECKOUT = '셔틀_미수회수_결제하기클릭',

  SHUTTLE_BOOKING_HISTORY_LIST_CLICK_ITEM = '셔틀_예약내역_클릭',

  CHARTER_MANUAL_CLICK_DESTINATION = '서비스소개_예상요금_목적지선택',
  CHARTER_MANUAL_GO_RENT = '서비스소개_통해_버스대절하러가기',
  CHARTER_MANUAL_SHARE = '버스대절_공유하는_방법',
  CHARTER_GO_USER_MANUAL = '버스대절소개_선택',
  CHARTER_RESERVE = '버스대절_예약종류_선택',
  CHARTER_JOURNEY_TYPE = '버스대절_여정_종류_선택',
  CHARTER_ARRIVAL_NAME = '목적지정보_탑승지검색',
  CHARTER_DEPARTURE_NAME = '출발지정보_탑승지검색',
  CHARTER_CALCULATION_CAPTURE = '가격정보만_알아보고_저장',
  CHARTER_EXTRA_INFO = '추가정보_안내정보_확인여부',
  CHARTER_AFTER_RESERVE = '버스대절_예약완료후_확인하는_정보',
  CHARTER_CLICK_RECENT_SEARCH = '버스대절_홈_최근검색_선택',
  CHARTER_SHARE_CALCULATION_LINK = '버스대절_차량선택_견적공유',

  JEJU_SHUTTLE_CLICK = '셔틀_제주셔틀홈_상품 클릭',

  SHUTTLE_BANNER_CLICK_1 = '셔틀_홈_하단배너1클릭',
  SHUTTLE_BANNER_CLICK_2 = '셔틀_홈_하단배너2클릭',
  SHUTTLE_BANNER_CLICK_3 = '셔틀_홈_하단배너3클릭',
}
